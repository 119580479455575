import Sortable from 'sortablejs'
import axios from 'axios'

const page = document.querySelector('#page').value
const csrf = document.querySelector('#csrf').value

axios.defaults.headers.common['X-CSRF-TOKEN'] = csrf

/**
 * Editors
 */
const editorConfig = {
  "useSearch": false,
  "language": "pl",
  "direction": "ltr",
  "enter": "BR",
  "toolbarAdaptive": false,
  "toolbarSticky": false,
  "showCharsCounter": false,
  "showWordsCounter": false,
  "showXPathInStatusbar": false,
  "minHeight": 50,
  "buttons": "bold,italic,underline,ul,ol,undo,redo,source"
}

const elements = document.querySelectorAll('textarea[data-jodit]');
elements.forEach(element => {
new Jodit(element, editorConfig);
})

/**
 * Uploaders
*/
const uploadGalleryElement = document.querySelector('#upload_gallery')
if (uploadGalleryElement) {
  uploadGalleryElement.addEventListener('change', e => {
    uploadGalleryElement.form.classList.add('busy')
    uploadGalleryElement.form.submit()
  })
}
const uploadSliderElement = document.querySelector('#upload_slider')
if (uploadSliderElement) {
  uploadSliderElement.addEventListener('change', e => {
    uploadSliderElement.form.classList.add('busy')
    uploadSliderElement.form.submit()
  })
}
const uploadDownloadsElement = document.querySelector('#upload_downloads')
if (uploadDownloadsElement) {
  uploadDownloadsElement.addEventListener('change', e => {
    uploadDownloadsElement.form.classList.add('busy')
    uploadDownloadsElement.form.submit()
  })
}

/**
 * Delete button
 */
const deleteButtonElements = document.querySelectorAll('button[data-action="delete"]')
if (deleteButtonElements) {
  deleteButtonElements.forEach(element => {
    element.addEventListener('click', e => {
      e.preventDefault()
      if (confirm('Czy chcesz usunąć to zdjęcie?')) {
        element.form.submit()
      }
    })
  })
}

const deleteFileButtonElements = document.querySelectorAll('button[data-action="delete_file"]')
if (deleteFileButtonElements) {
  deleteFileButtonElements.forEach(element => {
    element.addEventListener('click', e => {
      e.preventDefault()
      if (confirm('Czy chcesz usunąć ten plik?')) {
        element.form.submit()
      }
    })
  })
}

/**
 * Sortable
 */
const galleryElement = document.querySelector('#gallery')
if (galleryElement) {
  Sortable.create(galleryElement, {
    onEnd: function () {
      updateGallery(page, 'gallery', galleryElement)
    }
  })
}

const sliderElement = document.querySelector('#slider')
if (sliderElement) {
  Sortable.create(sliderElement, {
    onEnd: function () {
      updateGallery(page, 'slider', sliderElement)
    }
  })
}

const downloadsElement = document.querySelector('#downloads')
if (downloadsElement) {
  Sortable.create(downloadsElement, {
    onEnd: function () {
      updateGallery(page, 'download', downloadsElement)
    }
  })
}

function updateGallery(page, section, wrapper) {
  let data = [];
  Object.keys(wrapper.children).forEach(key => {
    data.push({
      id: parseInt(wrapper.children[key].dataset.id, 10),
      priority: parseInt(key, 10)
    })
  })

  axios.post(`/admin/${section}/priority`, {
    data,
    page,
    section
  })
}

/**
 * Photos removal
 */
let photoDeleteButtons = document.querySelectorAll('button[data-action=delete]')
if (photoDeleteButtons) {
  photoDeleteButtons.forEach(button => {
    button.addEventListener('pointerdown', e => {
      e.stopPropagation()
    }, {
      passive: true
    })
  })
}
